import { RiSearchLine } from '@remixicon/react';
import React from 'react';
import type { ReactNode } from 'react';
import { type VariantProps, tv } from 'tailwind-variants';
import { cn, focusInput, hasErrorInput } from '~/lib/utils';

export function Label({ id, children, className }: { id: string; children: ReactNode; className?: string }) {
  return (
    <label htmlFor={id} className={cn('block w-full mb-2 text-sm font-medium', className)}>
      {children}
    </label>
  );
}

const inputStyles = tv({
  base: [
    // base
    'relative block w-full appearance-none rounded-md border px-2.5 py-2 shadow-sm outline-none transition sm:text-sm',
    // border color
    'border-gray-300 dark:border-gray-800',
    // text color
    'text-gray-900 dark:text-gray-50',
    // placeholder color
    'placeholder-gray-400 dark:placeholder-gray-500',
    // background color
    'bg-white dark:bg-gray-950',
    // disabled
    'disabled:border-gray-300 disabled:bg-gray-100 disabled:text-gray-400',
    'disabled:dark:border-gray-700 disabled:dark:bg-gray-800 disabled:dark:text-gray-500',
    // file
    [
      'file:-my-2 file:-ml-2.5 file:cursor-pointer file:rounded-l-[5px] file:rounded-r-none file:border-0 file:px-3 file:py-2 file:outline-none focus:outline-none disabled:pointer-events-none file:disabled:pointer-events-none',
      'file:border-solid file:border-gray-300 file:bg-gray-50 file:text-gray-500 file:hover:bg-gray-100 file:dark:border-gray-800 file:dark:bg-gray-900 file:hover:dark:bg-gray-800 file:disabled:dark:border-gray-700',
      'file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem]',
      'file:disabled:bg-gray-100 file:disabled:text-gray-500 file:disabled:dark:bg-gray-800',
    ],
    // focus
    focusInput,
    // remove search cancel button
    '[&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden',
  ],
  variants: {
    hasError: {
      true: hasErrorInput,
    },
    // number input
    enableStepper: {
      false:
        '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
    },
  },
});

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputStyles> {
  inputClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputClassName, hasError, enableStepper = true, type, ...props }: InputProps, forwardedRef) => {
    const isPassword = type === 'password';
    const isSearch = type === 'search';

    return (
      <div className={cn('relative w-full', className)} tremor-id="tremor-raw">
        <input
          ref={forwardedRef}
          type={type}
          className={cn(
            inputStyles({ hasError, enableStepper }),
            {
              'pl-8': isSearch,
              'pr-10': isPassword,
            },
            inputClassName
          )}
          {...props}
        />
        {isSearch && (
          <div
            className={cn(
              // base
              'pointer-events-none absolute bottom-0 left-2 flex h-full items-center justify-center',
              // text color
              'text-gray-400 dark:text-gray-600'
            )}
          >
            <RiSearchLine className="size-[1.125rem] shrink-0" aria-hidden="true" />
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input, inputStyles, type InputProps };
